import {FC, useEffect} from 'react'
import NProgress from 'nprogress'
import {Box, LinearProgress} from '@mui/material'

const LoadingScreen: FC = () => {
    useEffect(() => {
        NProgress.start()

        return () => {
            NProgress.done()
        }
    }, [])

    return (
        <Box sx={{width: '100%'}}>
            <LinearProgress />
        </Box>
    )
}

export default LoadingScreen
