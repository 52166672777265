import {FC, ReactNode} from 'react'
import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react'
import {useRouter} from 'next/router'
import LoadingScreen from '@/components/widgets/LoadingScreen'
import useGenevaUser from '@/hooks/useGenevaUser'
import ForbiddenError from '@/views/error/ForbiddenError'
import {clarity} from 'react-microsoft-clarity'

interface OwnProps {
    children?: ReactNode
}

type Props = OwnProps

const ProtectRoutes: FC<Props> = (props) => {
    const {isLoading} = useAuth0()
    const router = useRouter()

    const {isGenevaLoading, isGenevaAuthenticated, genevaUser} = useGenevaUser()

    if (isLoading || isGenevaLoading) {
        return <LoadingScreen />
    }

    if (!isGenevaAuthenticated) {
        return <LoadingScreen />
    }

    if (!genevaUser) {
        return <LoadingScreen />
    }

    if (genevaUser) {
        if (process.env.hasClarityProjectId === 'true') {
            console.log('Identifying Clarity user')
            clarity.identify('USER_ID', {userProperty: genevaUser.id})
            clarity.setTag('genevaUserName', genevaUser.full_name)
        }
    }

    const path = router.pathname
    const isBasePath = path && path === '/'
    const isSupplierPortalPath = path && path.startsWith('/supplier_portal')
    const isWorkerPortalPath = path && path.startsWith('/worker_portal')
    if (genevaUser.is_provider_worker) {
        return <>{props.children}</>
    } else if (genevaUser.is_supplier_portal_worker) {
        if (isSupplierPortalPath || isWorkerPortalPath) {
            return <>{props.children}</>
        }
        if (isBasePath) {
            router.push('/supplier_portal')
        }
    } else if (genevaUser.is_supplier_service_worker) {
        if (isWorkerPortalPath) {
            return <>{props.children}</>
        }
    }
    return <ForbiddenError />
}

export default withAuthenticationRequired(ProtectRoutes, {
    onRedirecting: () => <LoadingScreen />,
})
