import {HTMLProps, ReactNode, forwardRef} from 'react'
import Head from 'next/head'
import {experimentalStyled} from '@mui/material/styles'

const PageRoot = experimentalStyled('div', {shouldForwardProp: (props) => props !== 'sx'})(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    height: '100%',
}))

interface PageProps extends HTMLProps<HTMLDivElement> {
    children?: ReactNode
    title?: string
    noPadding?: boolean
}

const Page = forwardRef<HTMLDivElement, PageProps>(({children, title = '', noPadding}, ref) => {
    return (
        <PageRoot sx={{paddingTop: noPadding ? 0 : 3}}>
            <div ref={ref as any}>
                <Head>
                    <title>{title}</title>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                </Head>
                {children}
            </div>
        </PageRoot>
    )
})

Page.displayName = 'Page'

export default Page
