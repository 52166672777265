import {FC} from 'react'
import {Box, Button, Container, Typography} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Page from '@/components/Page'
import {Theme} from '@mui/system'

const ForbiddenError: FC = () => {
    const mobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'))

    return (
        <Page title="Forbidden">
            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    minHeight: '100%',
                    px: 3,
                    py: '80px',
                }}
            >
                <Container maxWidth="lg">
                    <Typography variant={mobileDevice ? 'h4' : 'h1'} sx={{color: 'text.primary', textAlign: 'center'}}>
                        403: Forbidden
                    </Typography>
                    <Typography variant="subtitle2" sx={{color: 'text.secondary', mt: 0.5, textAlign: 'center'}}>
                        You either tried some shady route or you came here by mistake. Whichever it is, try using the
                        navigation.
                    </Typography>
                    <Box sx={{display: 'flex', justifyContent: 'center', mt: 6}}>
                        <Button color="primary" href="/" variant="outlined">
                            Back to Home
                        </Button>
                    </Box>
                </Container>
            </Box>
        </Page>
    )
}

export default ForbiddenError
