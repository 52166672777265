import authAxios from './authAxios'
import {
    TwilioPortalIFrameEventInterface,
    TwilioPortalSuggestionInterface,
    TwilioPortalTaskVerificationInterface,
    TwilioTaskClientInterface,
    TwilioTaskInterface,
    TwilioTaskLeadInterface,
    TwilioTaskNoteInterface,
} from '@/interfaces/twilio'
import {GenevaApplicationUserData} from '@/lib/api/geneva/application_user'

interface TwilioPortalIFrameEventResult {
    twilio_task: TwilioTaskInterface
}

export async function handleTwilioPortalIFrameEvent(twilio_portal_iframe_event: TwilioPortalIFrameEventInterface) {
    return await authAxios.post<TwilioPortalIFrameEventResult>(
        '/api/twilio_portal/iframe_event',
        twilio_portal_iframe_event,
    )
}

export interface TwilioPortalSuggestionResult {
    search_string: string
    suggestions: TwilioPortalSuggestionInterface[]
}

export async function getTwilioPortalSuggestions(
    search_string: string,
    is_phone_search: boolean,
    abort?: AbortController,
) {
    let config = {}
    if (abort) {
        config = {signal: abort.signal}
    }
    return await authAxios.get<TwilioPortalSuggestionResult>(
        `/api/twilio_portal/suggestion_persons?search_string=${search_string}&is_phone_search=${is_phone_search}`,
        config,
    )
}

export async function saveTwilioTask(twilio_task: TwilioTaskInterface) {
    return await authAxios.post('/api/twilio_portal/tasks', twilio_task)
}

interface TwilioTaskVerifiedResult {
    twilio_task: TwilioTaskInterface
}

export async function handleTwilioTaskVerified(verification: TwilioPortalTaskVerificationInterface) {
    return await authAxios.post<TwilioTaskVerifiedResult>('api/twilio_portal/tasks/verification', verification)
}

export async function handleTwilioTaskUnverified(twilio_task_sid: string) {
    return await authAxios.post<TwilioTaskVerifiedResult>(`api/twilio_portal/tasks/${twilio_task_sid}/unverification`)
}

interface TwilioTaskNoteResult {
    twilio_task_note: TwilioTaskNoteInterface
}

export async function getNewTwilioTaskNoteForTwilioTaskSID(twilio_task_sid: string) {
    return await authAxios.get<TwilioTaskNoteResult>(`/api/twilio_portal/tasks/${twilio_task_sid}/new_twilio_task_note`)
}

export async function saveTwilioTaskNote(twilioTaskNote: TwilioTaskNoteInterface) {
    return await authAxios.post<TwilioTaskNoteResult>('/api/twilio_portal/task_notes', twilioTaskNote)
}

interface TwilioTaskClientResult {
    twilio_task_client: TwilioTaskClientInterface
}

export async function saveTwilioTaskClient(twilioTaskClient: TwilioTaskClientInterface) {
    return await authAxios.post<TwilioTaskClientResult>('/api/twilio_portal/tasks/task_client', twilioTaskClient)
}

interface TwilioTaskLeadResult {
    twilio_task_lead: TwilioTaskLeadInterface
}

export async function saveTwilioTaskLead(twilioTaskLead: TwilioTaskLeadInterface) {
    return await authAxios.post<TwilioTaskLeadResult>('/api/twilio_portal/tasks/task_lead', twilioTaskLead)
}

export async function getApplicationUserForTwilioWorkerSid(twilio_worker_sid: string) {
    return await authAxios.get<GenevaApplicationUserData>(
        `/api/twilio_portal/workers/${twilio_worker_sid}/application_user`,
    )
}
