import {format} from 'date-fns/format'
import {enAU} from 'date-fns/locale'
import {formatDuration} from 'date-fns/formatDuration'
import {intervalToDuration} from 'date-fns/intervalToDuration'
import {parse} from 'date-fns/parse'
import {AssessmentQuestionInterface} from '@/interfaces/assessment_types'
import {isValidDate} from '@/utils/general'
import {DurationUnit} from 'date-fns/types'

export const genevaTimeRegExp = /^(?:\d|[01]\d|2[0-3]):[0-5]\d:[0-5]\d/
export const genevaDateTimeRegExp = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/

function isNull(value: unknown) {
    return typeof value === 'object' && !value
}

export function isNullOrUndeclared(value: unknown) {
    return typeof value === 'undefined' || isNull(value)
}

export const isEmptyString = (value: any) => {
    return value === ''
}

export function formatPercentage(value: unknown, roundTo: number = 2): string {
    if (isNullOrUndeclared(value)) {
        return ''
    }
    if (typeof value === 'string') {
        value = parseFloat(value)
        if (isNaN(Number(value))) {
            return ''
        }
    }
    return `${(Number(value) * 100).toFixed(roundTo)}%`
}

export function formatCurrency(value: unknown, roundTo: number = 2, negativeFormatting = true): string {
    if (isNullOrUndeclared(value)) {
        return ''
    }
    if (typeof value === 'string') {
        value = parseFloat(value)
        if (isNaN(Number(value))) {
            return ''
        }
    }
    let negative = false
    if (Number(value) < 0) {
        negative = true
    }
    const formattedValue = Math.abs(Number(value)).toLocaleString('en-AU', {
        minimumFractionDigits: roundTo,
        maximumFractionDigits: roundTo,
    })
    return negative && negativeFormatting ? `($${formattedValue})` : `$${formattedValue}`
}

export function formatNumber(value: unknown, roundTo: number = 2): string {
    if (isNullOrUndeclared(value)) {
        return ''
    }
    if (typeof value === 'string') {
        value = parseFloat(value)
        if (isNaN(Number(value))) {
            return ''
        }
    }
    return Math.abs(Number(value)).toLocaleString('en-AU', {
        minimumFractionDigits: roundTo,
        maximumFractionDigits: roundTo,
    })
}

// See https://date-fns.org/docs/Getting-Started for format string
export function formatDate(date: unknown, formatStr: string = 'dd-LLL-yyyy'): string {
    if (isNullOrUndeclared(date)) {
        return ''
    }
    if (typeof date === 'string') {
        if (!date) {
            return ''
        }
        date = new Date(date)
    }
    return format(new Date(date as Date), formatStr, {
        locale: enAU,
    })
}

export function formatDateTime(date: unknown, inputFormat = 'yyyy-MM-dd pp') {
    return formatDate(date, inputFormat)
}

export function formatDateAsISO(date: unknown, inputFormat = 'dd/MM/yyyy') {
    if (isNullOrUndeclared(date)) {
        return ''
    }
    if (typeof date === 'string') {
        if (!date) {
            return ''
        }
        try {
            date = parse(date, inputFormat, new Date())
        } catch (error: any) {
            console.error({error})
            return ''
        }
    }
    if (!isValidDate(date)) {
        return ''
    }
    return formatDate(date, 'yyyy-MM-dd')
}

export function formatDateAsISOWithoutTimezone(date: unknown, removeSeconds?: boolean) {
    if (isNullOrUndeclared(date)) {
        return ''
    }
    if (typeof date === 'string') {
        if (!date) {
            return ''
        }
        date = new Date(date)
    }
    if (!isValidDate(date)) {
        return ''
    }
    if (date instanceof Date) {
        if (removeSeconds) {
            date.setSeconds(0, 0)
        }
    }
    return `${formatDate(date, 'yyyy-MM-dd')}T${formatDate(date, 'HH:mm:ss')}`
}

export function formatTimeAsISOWithoutTimezone(date: unknown) {
    if (isNullOrUndeclared(date)) {
        return ''
    }
    if (typeof date === 'string') {
        if (!date) {
            return ''
        }
        date = new Date(date)
    }
    if (!isValidDate(date)) {
        return ''
    }
    return formatDate(date, 'HH:mm:ss')
}

// See https://date-fns.org/docs/Getting-Started for format string
export function formatTime(date: unknown, formatStr: string = 'p'): string {
    if (isNullOrUndeclared(date)) {
        return ''
    }
    if (typeof date === 'string') {
        if (!date) {
            return ''
        }
        if (genevaTimeRegExp.test(date)) {
            date = new Date(`1-1-1970 ${date}`)
        } else if (genevaDateTimeRegExp.test(date)) {
            date = new Date(date)
        } else {
            throw new Error(`Unknown date format ${date}`)
        }
    }
    if (!isValidDate(date)) {
        return ''
    }
    return format(new Date(date as Date), formatStr, {
        locale: enAU,
    })
}

export const formatBoolean = (value: boolean | null | undefined): string => {
    if (value) {
        return 'Yes'
    }
    return 'No'
}

export const formatDateInterval = (
    startTime: Date | string,
    endTime: Date | string,
    format?: DurationUnit[],
): string | undefined => {
    if (isNullOrUndeclared(startTime) || isNullOrUndeclared(endTime)) {
        return 'Invalid Times'
    }

    function toDate(value: any, name: string): Date {
        if (value instanceof Date) {
            return value
        }
        if (typeof value === 'string') {
            if (genevaTimeRegExp.test(value)) {
                return new Date(`1-1-1970 ${value}`)
            } else if (genevaDateTimeRegExp.test(value)) {
                return new Date(value)
            }
        }
        throw new Error(`Invalid date format for ${name}: ${value}`)
    }

    let duration
    try {
        startTime = toDate(startTime, 'startTime')
        endTime = toDate(endTime, 'endTime')
        duration = intervalToDuration({
            start: startTime,
            end: endTime,
        })
    } catch (err: any) {
        console.error(err)
        return undefined
    }
    if (!format) {
        format = []
        if (duration.years && duration.years > 0) {
            format = ['years', 'months']
        } else if (duration.months && duration.months > 0) {
            format = ['months', 'days']
        } else if (duration.days && duration.days > 0) {
            format = ['days', 'hours']
        } else if (duration.hours && duration.hours > 0) {
            format = ['hours', 'minutes']
        } else if (duration.minutes && duration.minutes > 0) {
            format = ['minutes', 'seconds']
        } else if (duration.seconds && duration.seconds > 0) {
            format = ['years']
        }
    }
    if (!startTime || !endTime) {
        return undefined
    }
    return formatDuration(duration, {format: format})
}

// Takes 'firstName LastName'
export const getInitials = (name: string = '') => {
    return name
        .replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map((v) => v && v[0].toUpperCase())
        .join('')
}

export function formatMedicareNumber(value: unknown): string {
    if (isNullOrUndeclared(value)) {
        return ''
    }
    // Convert to string & remove all whitespace
    const newVal = String(value).replace(/\s/g, '')
    if (newVal.length !== 11) {
        return newVal
    }
    return `${newVal.slice(0, 4)} ${newVal.slice(4, 9)} ${newVal[9]} ${newVal[10]}`
}

export function formatBSB(value: unknown): string {
    if (isNullOrUndeclared(value)) {
        return ''
    }
    // Convert to string & remove all whitespace
    const newVal = String(value).replace(/\s/g, '')
    if (newVal.length !== 6) {
        return newVal
    }
    return `${newVal.slice(0, 3)}-${newVal.slice(3, 6)}`
}

// Function to replace a string with placeholders:
//  eg: 'My name is {name} and my age is {age}'
//  replacements = {
//     name: 'Silvia',
//     age: '88',
//  }
// yielding: My name is Silvia and my age is 88
// Useful for the sending of URL values from Stockholm to be filled by Milan
interface replacementsInterface {
    [key: string]: string
}

export function replacePlaceholders(text: string, replacements: replacementsInterface) {
    return text.replace(/{(.*?)}/g, (placeholder) => {
        return replacements[placeholder.substring(1, placeholder.length - 1)] || 'N/A'
    })
}

export function truncateString(str: string, num: number) {
    if (isNullOrUndeclared(str)) {
        return null
    }
    if (str.length <= num) {
        return str
    }
    return str.slice(0, num) + '...'
}

export const truncateStringToWordCount = (text: string | null | undefined, wordCount: number) => {
    if (!text) {
        return text
    }
    let str = text.split(' ').slice(0, wordCount).join(' ')
    if (str.length === text.length) {
        return str
    } else {
        return str + '...'
    }
}

export function formatAlertText(question: AssessmentQuestionInterface) {
    if (!question.text_response) {
        return question.alert_text_template
    } else {
        return `${question.alert_text_template}: ${question.text_response}`
    }
}

export async function formatPhone(value: unknown, returnE164: boolean = false) {
    if (isNullOrUndeclared(value)) {
        return ''
    }
    if (typeof value !== 'string') {
        return ''
    }
    const {parsePhoneNumber} = await import('libphonenumber-js')
    const parsed = parsePhoneNumber(value, 'AU')
    if (parsed) {
        if (returnE164) {
            const numberChunks = parsed.format('E.164').match(/.{1,3}/g)
            if (numberChunks) {
                return numberChunks.join(' ')
            }
        }
        return parsed.formatNational()
    }
    return ''
}

export function formatAppointmentTime(date: Date) {
    const hours = new Date(date).getHours()
    const minutes = new Date(date).getMinutes()
    return 1 + ((hours - 1) % 12) + ':' + minutes.toString().padStart(2, '0') + ' ' + (hours > 11 ? 'PM' : 'AM')
}

export function capitalizeFirstLetter(string: string) {
    if (isNullOrUndeclared(string)) {
        return ''
    }
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const titleCase = (string: string) => {
    return string.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
    })
}

export const replaceSpaceWithNbsp = (text: string) => {
    return text.replace(/ /g, '\u00a0')
}

export function formatMinutes(minutes: number): string {
    if (minutes === 0) {
        return '0m'
    }
    const isNegative = minutes < 0
    if (isNegative) {
        minutes = Math.abs(minutes)
    }
    const days = Math.floor(minutes / (24 * 60))
    const hours = Math.floor((minutes % (24 * 60)) / 60)
    const remainingMinutes = minutes % 60

    let result = ``
    if (days !== 0) {
        result += isNegative ? `-${days}d ` : `${days}d `
    }
    if (hours !== 0) {
        result += isNegative ? `-${hours}h ` : `${hours}h `
    }
    if (remainingMinutes !== 0) {
        result += isNegative ? `-${remainingMinutes}m` : `${remainingMinutes}m`
    }

    return result.trim()
}

export const commaAndJoinArray = (array: string[], separator: string = ', ', lastSeparator: string = ' and ') => {
    if (array.length === 0) {
        return ''
    }
    if (array.length === 1) {
        return array[0]
    }
    if (array.length === 2) {
        return array.join(lastSeparator)
    }
    const last = array.pop()
    return `${array.join(separator)}${lastSeparator}${last}`
}
