import axios from 'axios'
import {StatusCodes} from 'http-status-codes'

const authAxios = axios.create({
    baseURL: process.env.NEXT_PUBLIC_GENEVA_API_URL ?? '',
})

export const setGenevaAuthToken = (token: string) => {
    if (token) {
        Object.assign(authAxios.defaults, {headers: {authorization: `Bearer ${token}`}})
    } else {
        Object.assign(authAxios.defaults, {headers: {authorization: ''}})
    }
}

authAxios.interceptors.request.use(
    (req) => {
        // @ts-ignore
        if (authAxios.defaults.headers['authorization']) {
            return req
        }
        const headers = authAxios.defaults.headers
        console.error('authAxios.interceptors.request.use.Headers', {headers})
        throw {message: 'the token is not available'}
    },
    (error) => {
        console.error('authAxios.interceptors.request.use', {error})
        return Promise.reject({error})
    },
)

authAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const sentryErrors = [StatusCodes.NOT_FOUND, StatusCodes.INTERNAL_SERVER_ERROR]
        if (error.response && error.response.status && sentryErrors.includes(error.response.status)) {
            const name = error.name
            const data = error.response?.data
            const message = error.response?.message
            const {withScope, captureException} = await import('@sentry/nextjs')
            withScope((scope) => {
                scope.setExtra('error', error)
                name && scope.setExtra('error.name', name)
                data && scope.setExtra('error.response.data', {data})
                message && scope.setExtra('error.response.message', {message})
                captureException(error)
            })
        }
        if (axios.isCancel(error)) {
            console.log('Request cancelled')
        } else {
            console.error('authAxios.interceptors.response.use', {error})
        }
        return Promise.reject({error})
    },
)

export default authAxios
