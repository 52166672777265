import {FC, useEffect, useState} from 'react'
import useSettings from '@/hooks/useSettings'
import dynamic from 'next/dynamic'

const SettingsDrawerComponent = dynamic(() => import('@/components/widgets/SettingsDrawerComponent'), {ssr: false})

const SettingsDrawer: FC = () => {
    const {settingsDrawerOpen, saveSettingsDrawerOpen} = useSettings()
    const [open, setOpen] = useState<boolean>(false)

    useEffect(() => {
        setOpen(settingsDrawerOpen)
    }, [settingsDrawerOpen])

    const handleClose = (): void => {
        setOpen(false)
        saveSettingsDrawerOpen(false)
    }

    return open && <SettingsDrawerComponent open={open} handleClose={handleClose} />
}

export default SettingsDrawer
