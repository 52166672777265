import merge from 'lodash/merge'
import {createTheme, responsiveFontSizes, ThemeOptions} from '@mui/material/styles'
import type {Direction, Theme} from '@mui/material'
import {THEMES} from '@/config/constants'
import {darkShadows, lightShadows} from './shadows'

interface ThemeConfig {
    direction?: Direction
    responsive_font_sizes?: boolean
    rounded_corners?: boolean
    theme?: string
}

// Module augmentation to add dark/light mode
// https://mui.com/customization/theming/#custom-variables
declare module '@mui/material/styles' {
    interface Theme {
        mode: string
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        mode?: string
    }
}

const baseOptions: ThemeOptions = {
    direction: 'ltr',
    // spacing: 6,
    components: {
        MuiAvatar: {
            styleOverrides: {
                fallback: {
                    height: '75%',
                    width: '75%',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    variant: 'h6',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 3,
                    overflow: 'hidden',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    mr: '16px',
                },
            },
        },
    },
    typography: {
        button: {
            fontWeight: 600,
        },
        fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
        h1: {
            fontWeight: 600,
            fontSize: '3.5rem',
        },
        h2: {
            fontWeight: 600,
            fontSize: '3rem',
        },
        h3: {
            fontWeight: 600,
            fontSize: '2.25rem',
        },
        h4: {
            fontWeight: 600,
            fontSize: '2rem',
        },
        h5: {
            fontWeight: 600,
            fontSize: '1.5rem',
        },
        h6: {
            fontWeight: 600,
            fontSize: '1.125rem',
        },
        overline: {
            fontWeight: 600,
        },
        caption: {
            fontStyle: 'italic',
            fontWeight: 600,
        },
    },
}

const themesOptions = {
    [THEMES.LIGHT]: {
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 0.86,
                            color: '#42526e',
                        },
                    },
                },
            },
        },
        palette: {
            action: {
                active: '#6b778c',
            },
            background: {
                default: '#f4f5f7',
                paper: '#ffffff',
            },
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'light',
            primary: {
                contrastText: '#ffffff',
                main: '#5664d2',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#172b4d',
                secondary: '#6b778c',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
        },
        shadows: lightShadows,
    },
    [THEMES.DARK]: {
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                    },
                },
            },
        },
        palette: {
            background: {
                default: '#171c24',
                paper: '#222b36',
            },
            divider: 'rgba(145, 158, 171, 0.24)',
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'dark',
            primary: {
                contrastText: '#ffffff',
                main: '#688eff',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#ffffff',
                secondary: '#919eab',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
        },
        shadows: darkShadows,
        devTheme: false,
    },
    [THEMES.NATURE]: {
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                    },
                },
            },
        },
        palette: {
            background: {
                default: '#1c2531',
                paper: '#293142',
            },
            divider: 'rgba(145, 158, 171, 0.24)',
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'dark',
            primary: {
                contrastText: '#ffffff',
                main: '#01ab56',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#ffffff',
                secondary: '#919eab',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
        },
        shadows: darkShadows,
        devTheme: false,
    },
    [THEMES.SUNRISE]: {
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 0.86,
                            color: '#42526e',
                        },
                    },
                },
            },
        },
        palette: {
            action: {
                active: '#6b778c',
            },
            background: {
                default: '#f4f5f7',
                paper: '#ffffff',
            },
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'light',
            primary: {
                contrastText: '#ffffff',
                main: '#FAA21B',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#172b4d',
                secondary: '#6b778c',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
        },
        shadows: lightShadows,
    },
    [THEMES.SUNSET]: {
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                    },
                },
            },
        },
        palette: {
            background: {
                default: '#171c24',
                paper: '#222b36',
            },
            divider: 'rgba(145, 158, 171, 0.24)',
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'dark',
            primary: {
                contrastText: '#ffffff',
                main: '#c27300',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#ffffff',
                secondary: '#919eab',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
        },
        shadows: darkShadows,
        devTheme: false,
    },
    [THEMES.LIGHT_CARMINE]: {
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 0.86,
                            color: '#42526e',
                        },
                    },
                },
            },
        },
        palette: {
            action: {
                active: '#6b778c',
            },
            background: {
                default: '#f4f5f7',
                paper: '#ffffff',
            },
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'light',
            primary: {
                contrastText: '#ffffff',
                main: '#F15E77',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#172b4d',
                secondary: '#6b778c',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
        },
        shadows: lightShadows,
    },
    [THEMES.PASTEL_VIOLET]: {
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 0.86,
                            color: '#42526e',
                        },
                    },
                },
            },
        },
        palette: {
            action: {
                active: '#6b778c',
            },
            background: {
                default: '#f4f5f7',
                paper: '#ffffff',
            },
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'light',
            primary: {
                contrastText: '#ffffff',
                main: '#CB95C3',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#172b4d',
                secondary: '#6b778c',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
        },
        shadows: lightShadows,
    },
    [THEMES.AUNTY_GRACE_DARK]: {
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                    },
                },
            },
        },
        palette: {
            background: {
                default: '#171c24',
                paper: '#222b36',
            },
            divider: 'rgba(145, 158, 171, 0.24)',
            error: {
                contrastText: '#000000',
                main: '#F5A3BF',
            },
            mode: 'dark',
            primary: {
                contrastText: '#000000',
                main: '#FFF68D',
            },
            success: {
                contrastText: '#000000',
                main: '#89E1D6',
            },
            text: {
                primary: '#ffffff',
                secondary: '#919eab',
            },
            warning: {
                contrastText: '#000000',
                main: '#FCD2BF',
            },
        },
        shadows: darkShadows,
        devTheme: false,
    },
    [THEMES.DEV_LIGHT]: {
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 0.86,
                            color: '#42526e',
                        },
                    },
                },
            },
        },
        palette: {
            action: {
                active: '#6b778c',
            },
            background: {
                default: '#f4f5f7',
                paper: '#ffffff',
            },
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'light',
            primary: {
                contrastText: '#ffffff',
                main: '#47BFAF',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#172b4d',
                secondary: '#6b778c',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
        },
        shadows: lightShadows,
        devTheme: true,
    },
    [THEMES.DEV_DARK]: {
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                    },
                },
            },
        },
        palette: {
            background: {
                default: '#171c24',
                paper: '#222b36',
            },
            divider: 'rgba(145, 158, 171, 0.24)',
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'dark',
            primary: {
                contrastText: '#ffffff',
                main: '#008e80',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#ffffff',
                secondary: '#919eab',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
        },
        shadows: darkShadows,
        devTheme: true,
    },
}

export const createMilanTheme = (config: ThemeConfig = {}): Theme => {
    let themeOptions = themesOptions[config.theme!]

    if (!themeOptions) {
        console.warn(new Error(`The theme ${config.theme} is not valid`))
        themeOptions = themesOptions[THEMES.LIGHT]
    }

    let theme = createTheme(
        merge(
            {},
            baseOptions,
            themeOptions,
            {
                ...(config.rounded_corners && {
                    shape: {
                        borderRadius: 16,
                    },
                }),
            },
            {
                ...{
                    components: {
                        MuiDataGrid: {
                            styleOverrides: {
                                cell: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    lineHeight: 'unset',
                                },
                                columnHeaderRow: {
                                    background: 'unset !important',
                                },
                                overlayWrapper: {
                                    minHeight: '104px',
                                },
                            },
                        },
                    },
                },
            },
            {
                direction: config.direction,
            },
        ),
    )

    if (config.responsive_font_sizes) {
        theme = responsiveFontSizes(theme)
    }

    return theme
}
