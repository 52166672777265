import authAxios from './authAxios'
import {
    AppointmentCancellationReasonInterface,
    AssessmentTypeInterface,
    ClientSubTaskTypeInterface,
    ClientTaskTypeInterface,
    CountryInterface,
    FinanceTaskTypeInterface,
    HomeCareEquipmentInterface,
    HomeCarePackageLevelInterface,
    HomeCareServiceCategoryInterface,
    HomeCareServiceInclusionInterface,
    LeadSubTaskTypeInterface,
    LeadTaskTypeInterface,
    LookupInterface,
    ModifiedMonashModelInterface,
    PricingOverlapConfigInterface,
    ServiceTimeUnitInterface,
    SupplierLeadSubTaskTypeInterface,
    SupplierLeadTaskTypeInterface,
    SupplierSubTaskTypeInterface,
    SupplierTaskTypeInterface,
    TitleInterface,
    VaccinationLookupInterface,
} from '@/interfaces/lookup_types'

interface LookupResultInterface {
    message: string
    results: TitleInterface[]
    count: number
}

export async function getTitles() {
    const {data, status} = await authAxios.get<LookupResultInterface>(`/api/lookups/titles`)
    return {data, status}
}

export async function getHomeCareServices() {
    const response = await authAxios.get(`/api/home_care_services`)
    return await response.data
}

export interface ConstantsInterface {
    active_incident_types: LookupInterface[]
    address_assessment_types: AssessmentTypeInterface[]
    address_states: LookupInterface[]
    assessment_other_party_types: LookupInterface[]
    assessment_types: AssessmentTypeInterface[]
    appointment_cancellation_provider_reasons: AppointmentCancellationReasonInterface[]
    appointment_cancellation_worker_reasons: AppointmentCancellationReasonInterface[]
    appointment_cancellation_client_reasons: AppointmentCancellationReasonInterface[]
    boolean_question_response: LookupInterface[]
    call_client_note_types: LookupInterface[]
    call_feedback_note_types: LookupInterface[]
    call_incident_note_types: LookupInterface[]
    cancellation_policy_fee_types: LookupInterface[]
    care_plan_action_categories: LookupInterface[]
    care_plan_concern_categories: LookupInterface[]
    care_plan_goal_categories: LookupInterface[]
    care_plan_statuses: LookupInterface[]
    carer_statuses: LookupInterface[]
    case_management_client_note_types: LookupInterface[]
    client_funds_transfer_sources: LookupInterface[]
    note_types: LookupInterface[]
    client_note_types: LookupInterface[]
    client_preference_priorities: LookupInterface[]
    client_preference_types: LookupInterface[]
    client_service_coordination_preference_types: LookupInterface[]
    client_service_coordination_preferences: LookupInterface[]
    client_task_types: ClientTaskTypeInterface[]
    client_sub_task_types: ClientSubTaskTypeInterface[]
    client_type: LookupInterface[]
    clinical_entity_types: LookupInterface[]
    contract_application_user_types: LookupInterface[]
    contract_service_statuses: LookupInterface[]
    countries: CountryInterface[]
    dependants_or_gives_care_types: LookupInterface[]
    eft_payment_batch_statuses: LookupInterface[]
    eft_payment_line_statuses: LookupInterface[]
    email_client_note_types: LookupInterface[]
    emergency_contact_types: LookupInterface[]
    enteral_feeding_types: LookupInterface[]
    fall_consultants: LookupInterface[]
    fall_types: LookupInterface[]
    finance_task_types: FinanceTaskTypeInterface[]
    feedback_note_types: LookupInterface[]
    feedback_types: LookupInterface[]
    genders: LookupInterface[]
    gst_amount: number
    home_care_equipment_items: HomeCareEquipmentInterface[]
    home_care_package_levels: HomeCarePackageLevelInterface[]
    home_care_service_categories: HomeCareServiceCategoryInterface[]
    home_care_service_inclusions: HomeCareServiceInclusionInterface[]
    incident_note_types: LookupInterface[]
    incident_types: LookupInterface[]
    income_tested_fee_statuses: LookupInterface[]
    indigenous_statuses: LookupInterface[]
    initial_care_manager_assessment_outcomes: LookupInterface[]
    issue_event_types: LookupInterface[]
    issue_sources: LookupInterface[]
    issue_statuses: LookupInterface[]
    journal_client_note_types: LookupInterface[]
    language_proficiencies: LookupInterface[]
    english_language_proficiencies: LookupInterface[]
    languages: LookupInterface[]
    lead_mac_priorities: LookupInterface[]
    lead_sales_contact_types: LookupInterface[]
    lead_referral_payment_donations: LookupInterface[]
    lead_task_reasons: LookupInterface[]
    lead_task_types: LeadTaskTypeInterface[]
    lead_sub_task_types: LeadSubTaskTypeInterface[]
    leave_contact_types: LookupInterface[]
    leave_types: LookupInterface[]
    living_arrangements: LookupInterface[]
    marital_statuses: LookupInterface[]
    meeting_appointment_cancellation_reasons: LookupInterface[]
    meeting_appointment_types: LookupInterface[]
    meeting_types: LookupInterface[]
    meeting_sub_types: LookupInterface[]
    methods_of_communication: LookupInterface[]
    modified_monash_model_classifications: ModifiedMonashModelInterface[]
    month_end_header_event_types: LookupInterface[]
    month_end_header_statuses: LookupInterface[]
    month_end_line_event_types: LookupInterface[]
    month_end_line_statuses: LookupInterface[]
    person_assessment_types: AssessmentTypeInterface[]
    person_relationship_types: LookupInterface[]
    person_representative_types: LookupInterface[]
    police_check_bodies: LookupInterface[]
    preferred_methods_of_communication: LookupInterface[]
    preferred_phones: LookupInterface[]
    preferred_pronouns: LookupInterface[]
    pricing_overlap_configs: PricingOverlapConfigInterface[]
    religions: LookupInterface[]
    representative_assessment_types: AssessmentTypeInterface[]
    review_sites: LookupInterface[]
    service_frequencies: LookupInterface[]
    service_statuses: LookupInterface[]
    service_time_units: ServiceTimeUnitInterface[]
    simplified_client_sub_task_types: ClientSubTaskTypeInterface[]
    supplier_lead_note_types: LookupInterface[]
    supplier_lead_task_types: SupplierLeadTaskTypeInterface[]
    supplier_lead_sub_task_types: SupplierLeadSubTaskTypeInterface[]
    supplier_note_types: LookupInterface[]
    supplier_payment_arrangements: LookupInterface[]
    supplier_payment_frequencies: LookupInterface[]
    supplier_payment_header_event_types: LookupInterface[]
    supplier_person_statuses: LookupInterface[]
    supplier_service_region_statuses: LookupInterface[]
    supplier_service_statuses: LookupInterface[]
    supplier_task_types: SupplierTaskTypeInterface[]
    supplier_sub_task_types: SupplierSubTaskTypeInterface[]
    supplier_types: LookupInterface[]
    task_categories: LookupInterface[]
    task_event_types: LookupInterface[]
    task_priorities: LookupInterface[]
    task_statuses: LookupInterface[]
    titles: LookupInterface[]
    twilio_task_skip_verification_reasons: LookupInterface[]
    user_client_sub_task_types: ClientSubTaskTypeInterface[]
    vaccination_statuses: LookupInterface[]
    vaccination_sub_types: LookupInterface[]
    vaccination_types: VaccinationLookupInterface[]
    vaccination_verification_statuses: LookupInterface[]
    written_languages: LookupInterface[]
}

interface ConstantsResultInterface {
    data: ConstantsInterface
    status: number
}

export async function getConstants(): Promise<ConstantsResultInterface> {
    const {data, status} = await authAxios.get<ConstantsInterface>(`/api/constants`)
    return {data, status}
}
