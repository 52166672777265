import {io} from 'socket.io-client';
import {milanIoClientOptions} from './constants';

// socket.io Client Side
// https://socket.io/docs/v4/client-socket-instance/
// TODO:
// https://socket.io/docs/v4/connection-state-recovery
// https://socket.io/docs/v4/client-options/#socket-options

const isBrowser = typeof window !== "undefined";

if (isBrowser) console.debug('milan.io.client initializing io(', milanIoClientOptions, ')');

export const milanIoClient = isBrowser ? io(milanIoClientOptions) : {
  // dummy object for !isBrowser to avoid type errors
  connected: false,
  io: {
    opts: {
      transports: milanIoClientOptions.transports,
    },
  },
  id: 'abcdef1234567890Fake',
  emit: () => {
  },
  on: () => {
  },
  off: () => {
  },
};

// TODO: stockholmIoClient ?
