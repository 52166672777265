import {FC, ReactNode, createContext, useState} from 'react'

export interface IsBusyContextValue {
    isBusy: boolean
    saveIsBusy: (update: boolean) => void
    isErrorModalOpen: boolean
    saveIsErrorModalOpen: (update: boolean) => void
}

interface IsBusyProviderProps {
    children?: ReactNode
}

const IsBusyContext = createContext<IsBusyContextValue>({
    isBusy: false,
    saveIsBusy: () => {},
    isErrorModalOpen: false,
    saveIsErrorModalOpen: () => {},
})

export const IsBusyProvider: FC<IsBusyProviderProps> = (props) => {
    const {children} = props
    const [isBusy, setIsBusy] = useState<boolean>(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false)

    const saveIsBusy = (updatedIsBusy: boolean): void => {
        setIsBusy(updatedIsBusy)
    }

    const saveIsErrorModalOpen = (updatedIsErrorModalOpen: boolean): void => {
        setIsErrorModalOpen(updatedIsErrorModalOpen)
    }

    return (
        <IsBusyContext.Provider
            value={{
                isBusy,
                saveIsBusy,
                isErrorModalOpen,
                saveIsErrorModalOpen,
            }}
        >
            {children}
        </IsBusyContext.Provider>
    )
}

export const IsBusyConsumer = IsBusyContext.Consumer

export default IsBusyContext
