import {FC, ReactNode, createContext, useEffect, useState} from 'react'
import {SettingsInterface} from '@/interfaces/layout_types'
import {THEMES} from '@/config/constants'
import {TwilioPortalIrysFlexMessageInterface} from '@/interfaces/twilio'
import {devThemeOverrideSettings} from '@/utils/general'

export interface SettingsContextValue {
    settings: SettingsInterface
    saveSettings: (update: SettingsInterface) => void
    settingsDrawerOpen: boolean
    saveSettingsDrawerOpen: (update: boolean) => void
}

interface SettingsProviderProps {
    children?: ReactNode
}

const initialSettings: SettingsInterface = {
    compact: false,
    direction: 'ltr',
    responsive_font_sizes: true,
    rounded_corners: true,
    theme: THEMES.LIGHT,
}

export const restoreSettings = (): SettingsInterface | null => {
    let settings = null

    try {
        const storedData: string | null = window.localStorage.getItem('settings')

        if (storedData) {
            settings = JSON.parse(storedData)
        } else {
            settings = {
                compact: false,
                direction: 'ltr',
                responsive_font_sizes: true,
                rounded_corners: true,
                theme: window.matchMedia('(prefers-color-scheme: dark)').matches ? THEMES.DARK : THEMES.LIGHT,
            }
        }
        // Override theme in non-production environments
        if (
            !process.env.NEXT_PUBLIC_ENVIRONMENT ||
            (process.env.NEXT_PUBLIC_ENVIRONMENT && process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production')
        ) {
            settings = devThemeOverrideSettings({...settings})
        }
    } catch (err: any) {
        console.error(err)
        // If stored data is not a strigified JSON this will fail,
        // that's why we catch the error
    }

    return settings
}

export const storeSettings = (settings: SettingsInterface): void => {
    window.localStorage.setItem('settings', JSON.stringify(settings))
}

const SettingsContext = createContext<SettingsContextValue>({
    settings: initialSettings,
    saveSettings: () => {},
    settingsDrawerOpen: false,
    saveSettingsDrawerOpen: () => {},
})

export const handleSendFlexMessage = (theme: string) => {
    if (window.parent) {
        let targetOrigin
        if (
            process.env.NEXT_PUBLIC_ENVIRONMENT == 'production' ||
            process.env.NEXT_PUBLIC_ENVIRONMENT == 'dev' ||
            process.env.NEXT_PUBLIC_ENVIRONMENT == 'staging'
        ) {
            targetOrigin = 'https://flex.twilio.com'
        } else {
            targetOrigin = 'http://localhost:3002'
        }
        const message: TwilioPortalIrysFlexMessageInterface = {
            message_type: 'settingsUpdate',
            theme: theme,
        }
        window.parent.postMessage(message, targetOrigin)
    }
}

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
    const {children} = props
    const [settings, setSettings] = useState<SettingsInterface>(initialSettings)
    const [settingsDrawerOpen, saveSettingsDrawerOpen] = useState<boolean>(false)

    useEffect(() => {
        const restoredSettings = restoreSettings()
        if (restoredSettings) {
            setSettings(restoredSettings)
        }
    }, [])

    const saveSettings = (updatedSettings: SettingsInterface): void => {
        setSettings(updatedSettings)
        handleSendFlexMessage(updatedSettings.theme)
        storeSettings(updatedSettings)
    }

    return (
        <SettingsContext.Provider
            value={{
                settings,
                saveSettings,
                settingsDrawerOpen,
                saveSettingsDrawerOpen,
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}

export const SettingsConsumer = SettingsContext.Consumer

export default SettingsContext
