import {AxiosResponse} from 'axios'
import authAxios from '@/lib/api/geneva/authAxios'

export const apiUrl = (path: string) => {
    const host = process.env.NEXT_PUBLIC_GENEVA_API_URL ?? ''
    return `${host}${path}`
}

export const authHeaders = (jwtToken: string) =>
    new Headers({
        Authorization: `Bearer ${jwtToken}`,
        Accept: 'application/json',
        // 'Content-Type': 'application/json'
    })

export const urlWithParams = (url: string, data = {}) =>
    Object.keys(data).length > 0 ? `${url}?${new URLSearchParams(data).toString()}` : url

export class APIError extends Error {
    public date: Date
    public data: any
    public response: AxiosResponse | undefined
    public request: any
    public status: number | undefined
    public headers: number | undefined
    public url: string

    constructor(url: string, ...params: any) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params)

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, APIError)
        }

        this.url = url
        this.name = 'APIError'
        this.date = new Date()
    }
}

export async function postStockholmURL(stockholmUrl: string) {
    return authAxios.post(stockholmUrl)
}
