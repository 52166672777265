export const THEMES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
    NATURE: 'NATURE',
    SUNRISE: 'SUNRISE',
    SUNSET: 'SUNSET',
    LIGHT_CARMINE: 'LIGHT_CARMINE',
    PASTEL_VIOLET: 'PASTEL_VIOLET',
    AUNTY_GRACE_DARK: 'AUNTY_GRACE_DARK',
    DEV_LIGHT: 'DEV_LIGHT',
    DEV_DARK: 'DEV_DARK',
}

export interface yesNoInterface {
    description: string
    id: string
}

export const yesNo: yesNoInterface[] = [
    {description: 'Yes', id: 'true'},
    {description: 'No', id: 'false'},
]
export const isNextAppBuildProd = process.env.NEXT_APP_BUILD_PROD === '1';

const milanIoCommonOptions = {
    addTrailingSlash: false,
    allowUpgrades: true,
    path: '/api/socket',
    transports: isNextAppBuildProd
      // ? ['polling', 'webtransport', 'websocket']
      // : ['polling', 'websocket']
      // ? ['webtransport', 'websocket', 'polling']
      // : ['websocket', 'polling']
      ? ['webtransport', 'polling', 'websocket']
      : ['polling', 'websocket']
};

// https://socket.io/docs/v4/server-options/
export const milanIoServerOptions = {
    ...milanIoCommonOptions,
}

// https://socket.io/docs/v4/client-options/
export const milanIoClientOptions = {
    ...milanIoCommonOptions,
    // rememberUpgrade: true,
    retries: 5,
}
