import {FC, ReactNode, createContext, useEffect, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {authenticateAuth0UserAgainstGeneva} from '@/lib/api/geneva/application_user'
import {GenevaUserInterface} from '@/interfaces/user_types'
import useSettings from '@/hooks/useSettings'
import {devThemeOverrideSettings} from '@/utils/general'

interface GenevaUserProviderProps {
    children: ReactNode
}

interface AuthState {
    isGenevaAuthenticated: boolean
    isGenevaLoading: boolean
    authenticationError: string | null
    genevaApplicationUserId: number | null
    genevaProviderId: number | null
    genevaUser: GenevaUserInterface | null
}

const initialAuthState: AuthState = {
    isGenevaAuthenticated: false,
    isGenevaLoading: false,
    authenticationError: null,
    genevaApplicationUserId: null,
    genevaProviderId: null,
    genevaUser: null,
}

const GenevaUserContext = createContext<AuthState>({
    ...initialAuthState,
})

export const GenevaUserProvider: FC<GenevaUserProviderProps> = ({children}) => {
    const {user, isAuthenticated, getAccessTokenSilently, logout} = useAuth0()
    const [genevaUser, setGenevaUser] = useState<GenevaUserInterface | null>(null)
    const [isGenevaAuthenticated, setIsGenevaAuthenticated] = useState<boolean>(false)
    const [genevaApplicationUserId, setGenevaApplicationUserId] = useState<number | null>(null)
    const [genevaProviderId, setGenevaProviderId] = useState<number | null>(null)
    const [isGenevaLoading, setIsGenevaLoading] = useState(false)
    const [authenticationError, setAuthenticationError] = useState<string | null>(null)
    const {settings, saveSettings} = useSettings()

    useEffect(() => {
        console.log(`GenevaUserProvider ${new Date().toLocaleString()}`, 'useEffect')
        const getStockholmToken = async () => {
            setIsGenevaLoading(true)
            console.log(`GenevaUserProvider ${new Date().toLocaleString()}`, 'Retrieving access token')
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
                    scope: 'write:all',
                },
            })
            if (!accessToken) {
                console.log(`GenevaUserProvider ${new Date().toLocaleString()}`, 'No access token found')
                const authError = `Unable to fetch Auth0 access token`
                setAuthenticationError(authError)
                logout({logoutParams: {returnTo: process.env.NEXT_PUBLIC_MILAN_URL}})
                setIsGenevaLoading(false)
                throw new Error(authError)
            }
            console.log(`GenevaUserProvider ${new Date().toLocaleString()}`, 'Access token found')
            try {
                console.log(`GenevaUserProvider ${new Date().toLocaleString()}`, `Retrieving Geneva user`)
                const {data} = await authenticateAuth0UserAgainstGeneva(user, accessToken)
                console.log(`GenevaUserProvider ${new Date().toLocaleString()}`, `Updating Geneva user`)
                const genevaUser = await data.application_user
                console.log(
                    `GenevaUserProvider ${new Date().toLocaleString()}`,
                    `Setting user ${genevaUser.description} data`,
                )
                setGenevaApplicationUserId(genevaUser.id)
                setGenevaProviderId(genevaUser.provider_id)
                setGenevaUser(genevaUser)
                console.log(`GenevaUserProvider ${new Date().toLocaleString()}`, `Updating user application settings`)

                let overrideSettings = {...settings}
                if (
                    !process.env.NEXT_PUBLIC_ENVIRONMENT ||
                    (process.env.NEXT_PUBLIC_ENVIRONMENT && process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production')
                ) {
                    overrideSettings = devThemeOverrideSettings(overrideSettings)
                    console.log(
                        `GenevaUserProvider ${new Date().toLocaleString()}`,
                        'Overriding theme for non-production environ',
                    )
                }

                saveSettings(overrideSettings)
                setIsGenevaAuthenticated(true)
                setIsGenevaLoading(false)
            } catch (error: any) {
                console.log(`GenevaUserProvider ${new Date().toLocaleString()}`, 'No Geneva user found')
                const authError = `No vita user exists with Auth0 user data: ${user ? user.email : 'Unknown'}`
                setAuthenticationError(authError)
                setIsGenevaAuthenticated(false)
                logout({logoutParams: {returnTo: process.env.NEXT_PUBLIC_MILAN_URL}})
                setIsGenevaLoading(false)
                const {withScope, captureException} = await import('@sentry/nextjs')
                withScope((scope) => {
                    scope.setExtra('error', error)
                    user && scope.setExtra('error.response.user', {user})
                    captureException(error)
                })
                throw new Error(authError)
            }
        }
        isAuthenticated && getStockholmToken()
    }, [isAuthenticated])

    return (
        <GenevaUserContext.Provider
            value={{
                isGenevaAuthenticated,
                isGenevaLoading,
                genevaApplicationUserId,
                genevaProviderId,
                genevaUser,
                authenticationError,
            }}
        >
            {children}
        </GenevaUserContext.Provider>
    )
}

export default GenevaUserContext
