import {useEffect, useState} from 'react'
import {AppProps} from 'next/app'
import Head from 'next/head'
import Router, {useRouter} from 'next/router'
import {LicenseInfo} from '@mui/x-license'
import {AppState, Auth0Provider} from '@auth0/auth0-react'
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'
import {AppCacheProvider} from '@mui/material-nextjs/v14-pagesRouter'
import CssBaseline from '@mui/material/CssBaseline'
import createEmotionCache from './createEmotionCache'
import {SnackbarProvider} from 'notistack'
import {clarity} from 'react-microsoft-clarity'
import {createMilanTheme} from '../theme'
import {SettingsProvider} from '../contexts/SettingsContext'
import {ConstantsProvider} from '../contexts/ConstantsContext'
import useSettings from '@/hooks/useSettings'
import ProtectRoutes from '@/components/ProtectRoutes'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3'
import SettingsDrawer from '@/components/widgets/SettingsDrawer'
import {GenevaUserProvider} from '../contexts/GenevaUserContext'
import {IsBusyProvider} from '../contexts/IsBusyContext'
import IsBusyBackdrop from '@/components/IsBusyBackdrop'
import {
    TwilioPortalFlexIrysMessageInterface,
    TwilioPortalIFrameEventInterface,
    TwilioPortalIrysFlexMessageInterface,
} from '@/interfaces/twilio'
import {getApplicationUserForTwilioWorkerSid, handleTwilioPortalIFrameEvent} from '@/lib/api/geneva/twilio'
import {devThemeOverrideSettings} from '@/utils/general'
import {SettingsInterface} from '@/interfaces/layout_types'
import GlobalStyles from '@mui/material/GlobalStyles'
import {milanIoClient} from '@/config/socketClient'

LicenseInfo.setLicenseKey(
    // MUI Pro Licence Key - Updated 23 Feb 2023
    '72406ee7b7b3c639dbc406952624304bTz04MzY3OSxFPTE3Mzg4MjIwMzUwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
)

export const clientSideEmotionCache = createEmotionCache()

const globalStyles = (
    <GlobalStyles
        styles={() => ({
            '*': {
                boxSizing: 'border-box',
                margin: 0,
                padding: 0,
            },
            html: {
                webkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
                height: '100%',
                width: '100%',
            },
            body: {
                height: '100%',
                width: '100%',
            },
            '#root': {
                height: '100%',
                width: '100%',
            },
        })}
    />
)

const onRedirectCallback = (appState?: AppState | undefined) => {
    const url = appState?.returnTo || '/'
    return Router.replace(url)
}

function VitaApp(props: AppProps) {
    const router = useRouter()
    const {settings, saveSettings} = useSettings()
    // https://socket.io/how-to/use-with-react
    // https://socket.io/docs/v4/client-socket-instance/
    // @ts-ignore
    const [milanIoConnected, setMilanIoConnected] = useState(milanIoClient.connected)
    // @ts-ignore
    const [milanIoHelloEvent, setMilanIoHelloEvent] = useState({})

    const {Component, pageProps} = props

    const handleFlexEvent = async (event: MessageEvent) => {
        const message: TwilioPortalFlexIrysMessageInterface = event.data
        if (message.message_type == 'reloadSettings' && message.worker_sid) {
            const {data} = await getApplicationUserForTwilioWorkerSid(message.worker_sid)
            let overrideSettings: SettingsInterface = {...data.application_user} as unknown as SettingsInterface
            if (
                !process.env.NEXT_PUBLIC_ENVIRONMENT ||
                (process.env.NEXT_PUBLIC_ENVIRONMENT && process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production')
            ) {
                overrideSettings = devThemeOverrideSettings(overrideSettings)
            }
            saveSettings(overrideSettings)
        } else {
            if (!message.task_sid) {
                await router.push(`/twilio_portal`)
            } else {
                const iFrameEvent: TwilioPortalIFrameEventInterface = {
                    task_sid: message.task_sid,
                    call_sid: message.call_sid,
                    call_from: message.call_from,
                    call_to: message.call_to,
                    direction: message.direction,
                }
                await handleTwilioPortalIFrameEvent(iFrameEvent)
                let callTarget
                if (message.direction == 'outbound') {
                    callTarget = message.call_to
                } else {
                    callTarget = message.call_from
                }
                if (callTarget) {
                    callTarget = callTarget.replace('+', '')
                }
                await router.push(`/twilio_portal/${message.task_sid}?call_target=${callTarget}`)
            }
        }
    }

    const messageListenFunction = async (event: MessageEvent) => {
        let expectedOrigin
        if (
            process.env.NEXT_PUBLIC_ENVIRONMENT == 'production' ||
            process.env.NEXT_PUBLIC_ENVIRONMENT == 'dev' ||
            process.env.NEXT_PUBLIC_ENVIRONMENT == 'staging'
        ) {
            expectedOrigin = 'https://flex.twilio.com'
        } else {
            expectedOrigin = 'http://localhost:3002'
        }
        if (expectedOrigin && event.origin.startsWith(expectedOrigin)) {
            await handleFlexEvent(event)
        }
    }

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side')
        if (jssStyles) {
            jssStyles.parentElement!.removeChild(jssStyles)
        }
    }, [])

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID) {
            console.log('Clarity project ID found. Initializing Clarity')
            clarity.init(process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID as string)
        } else {
            console.log('Clarity Project ID not set')
        }
    }, [process.env])

    useEffect(() => {
        window.addEventListener('message', messageListenFunction)
        if (window.parent) {
            let targetOrigin
            if (
                process.env.NEXT_PUBLIC_ENVIRONMENT == 'production' ||
                process.env.NEXT_PUBLIC_ENVIRONMENT == 'dev' ||
                process.env.NEXT_PUBLIC_ENVIRONMENT == 'staging'
            ) {
                targetOrigin = 'https://flex.twilio.com'
            } else {
                targetOrigin = 'http://localhost:3002'
            }
            const message: TwilioPortalIrysFlexMessageInterface = {message_type: 'initialLoad'}
            console.log(`irysSend to ${targetOrigin}`, message)
            window.parent.postMessage(message, targetOrigin)
        }
    }, [])

    useEffect(() => {
        function milanOnConnect() {
            console.debug('milan.io.client', milanIoClient.id, '->connect')
            setMilanIoConnected(true)
            const data = {message: 'world'}
            console.debug('milan.io.client', milanIoClient.id, '<-hello', data)
            milanIoClient.emit('hello', data)
            // TODO: Authentication? (Not needed yet as this is purely for keepalive)
        }

        function milanOnDisconnect() {
            console.debug('milan.io.client', milanIoClient.id, '->disconnect')
            setMilanIoConnected(false)
        }

        function milanOnHello(value: object) {
            console.debug('milan.io.client', milanIoClient.id, '->hello', value)
            setMilanIoHelloEvent(value)
        }

        if (milanIoClient.connected) {
            console.debug('milan.io.client', milanIoClient.id, 'already connected')
            milanOnConnect()
        }

        milanIoClient.on('connect', milanOnConnect)
        milanIoClient.on('disconnect', milanOnDisconnect)
        milanIoClient.on('hello', milanOnHello)

        return () => {
            milanIoClient.off('connect', milanOnConnect)
            milanIoClient.off('disconnect', milanOnDisconnect)
            milanIoClient.off('hello', milanOnHello)
        }
    }, [])

    const theme = createMilanTheme({
        responsive_font_sizes: settings.responsive_font_sizes,
        rounded_corners: settings.rounded_corners,
        theme: settings.theme,
    })

    return (
        <AppCacheProvider {...props}>
            <Head>
                <title>Irys</title>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
            </Head>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <StyledEngineProvider injectFirst>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <SnackbarProvider dense maxSnack={3}>
                                <CssBaseline enableColorScheme />
                                {globalStyles}
                                <Auth0Provider
                                    domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
                                    clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
                                    authorizationParams={{
                                        redirect_uri:
                                            typeof window !== 'undefined' ? window.location.origin : undefined,
                                        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE!,
                                        scope: 'openid profile email write:all offline_access',
                                    }}
                                    onRedirectCallback={onRedirectCallback}
                                    useRefreshTokens={true}
                                >
                                    <GenevaUserProvider>
                                        <SettingsDrawer />
                                        <ProtectRoutes>
                                            <ConstantsProvider>
                                                <IsBusyProvider>
                                                    <IsBusyBackdrop>
                                                        <Component {...pageProps} />
                                                    </IsBusyBackdrop>
                                                </IsBusyProvider>
                                            </ConstantsProvider>
                                        </ProtectRoutes>
                                    </GenevaUserProvider>
                                </Auth0Provider>
                            </SnackbarProvider>
                        </LocalizationProvider>
                    </StyledEngineProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </AppCacheProvider>
    )
}

// This exists because we need SettingsProvider at a higher level so VitaApp.useEffect works correctly
function MyApp(props: AppProps) {
    return (
        <SettingsProvider>
            <VitaApp {...props} />
        </SettingsProvider>
    )
}

let MilanApp

MilanApp = MyApp

// if (process.env.hasApplicationInsights === 'true') {
//     MilanApp = withApplicationInsights({
//         instrumentationKey: process.env.appInsightsInstrumentationKey,
//         isEnabled: true, // process.env.NODE_ENV === 'production'
//     })(MyApp)
// } else {
//     MilanApp = MyApp
// }

export default MilanApp
