import {useEffect, useRef} from 'react'
import {formatDate, formatTime, isNullOrUndeclared} from '@/utils/formatter'
import {addYears} from 'date-fns/addYears'
import {addMonths} from 'date-fns/addMonths'
import {darken, lighten} from '@mui/material'
import {addDays} from 'date-fns/addDays'
import {SettingsInterface} from '@/interfaces/layout_types'
import {isEmpty} from 'lodash'

export const updateObject = (oldObject: any, updatedProperties: any) => {
    return {
        ...oldObject,
        ...updatedProperties,
    }
}

export const acceptAllRegExp = /^.*$/
export const acRegExp = /^(AC)(\d){8}/
export const macReferralCodeRegExp = /^(\d|-){1,14}$/
export const pensionRegExp = /(\d){9}[a-zA-Z]$/
export const medicareNumberRegExp = /^(\d){10}/
export const medicarePersonNumberRegExp = /^(\d)/
export const dateFromGenevaDate = (date: string | Date): Date => {
    return new Date(date)
}

export const isStringInteger = (string: string) => {
    return /^-?\d+$/.test(string)
}

export const isValidDate = (date: any) => {
    return date instanceof Date && !isNaN(date.getTime())
}

export const isValidTimeOrTimeString = (date: any) => {
    try {
        formatTime(date)
        return true
    } catch {
        return false
    }
}

export const firstDayOfCurrentMonth = () => {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth(), 1)
}

export const firstDayOfMonthForDate = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1)
}

export const lastDayOfMonthForDate = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

export const lastDayOfCurrentMonth = () => {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

export const lastDayOfPreviousMonth = () => {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth(), 0)
}

export const firstDayOfPreviousMonth = () => {
    return firstDayOfMonthForDate(lastDayOfPreviousMonth())
}

export const firstDayOfNextMonth = () => {
    const date = new Date()
    return addMonths(new Date(date.getFullYear(), date.getMonth(), 1), 1)
}

export const lastDayOfNextMonth = () => {
    const date = firstDayOfNextMonth()
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

export const firstDayOfNextMonthPlusOne = () => {
    const date = new Date()
    return addMonths(new Date(date.getFullYear(), date.getMonth(), 1), 2)
}

export const lastDayOfNextMonthPlusOne = () => {
    const date = firstDayOfNextMonthPlusOne()
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

export const firstDayOfNextMonthPlusTwo = () => {
    const date = new Date()
    return addMonths(new Date(date.getFullYear(), date.getMonth(), 1), 3)
}

export const lastDayOfNextMonthPlusTwo = () => {
    const date = firstDayOfNextMonthPlusTwo()
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

export const firstDayOfPreviousMonthLessOne = () => {
    const date = new Date()
    return addMonths(new Date(date.getFullYear(), date.getMonth(), 1), -2)
}

export const lastDayOfPreviousMonthLessOne = () => {
    const date = firstDayOfPreviousMonthLessOne()
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

export const financialYearForDate = (date: Date) => {
    if (date.getMonth() >= 6) {
        return date.getFullYear() + 1
    }
    return date.getFullYear()
}

export const currentFinancialYear = () => {
    return financialYearForDate(new Date())
}

export const firstDayOfFinancialYearForDate = (date: Date) => {
    const year = financialYearForDate(date)
    return new Date(year - 1, 6, 1)
}

export const firstDayOfCurrentFinancialYear = () => {
    return firstDayOfFinancialYearForDate(new Date())
}

export const lastDayOfFinancialYearForDate = (date: Date) => {
    const year = financialYearForDate(date)
    return new Date(year, 5, 30)
}

export const lastDayOfCurrentFinancialYear = () => {
    return lastDayOfFinancialYearForDate(new Date())
}

export const firstDayOfCurrentYear = () => {
    const date = new Date()
    return new Date(date.getFullYear(), 0, 1)
}

export const lastDayOfCurrentYear = () => {
    const date = new Date()
    return new Date(date.getFullYear(), 12, 0)
}

export const firstDayOfPreviousYear = () => {
    const date = new Date()
    return new Date(date.getFullYear() - 1, 0, 1)
}

export const lastDayOfPreviousYear = () => {
    const date = new Date()
    return new Date(date.getFullYear() - 1, 12, 0)
}

export const currentAgeInYears = (dateOfBirth: Date) => {
    const timeDiff = Date.now() - dateOfBirth.getTime()
    const age = new Date(timeDiff)
    return Math.abs(age.getUTCFullYear() - 1970)
}

export const bsbRegex = /^\d{6}/

export const getNumericCharactersFromString = (value: string): string => {
    if (isNullOrUndeclared(value)) {
        return ''
    }
    return value.replace(/\D/g, '')
}

export const maxDate = new Date(8640000000000000)
export const minDate = new Date(-8640000000000000)

export const isDateValid = (value: any): boolean => {
    return !isNullOrUndeclared(value) && value instanceof Date && !isNaN(value.valueOf())
}

export const getDateAtMidnight = (date: Date): Date => {
    let updatedDate = new Date(date)
    updatedDate.setHours(0, 0, 0, 0)
    return updatedDate
}

export const getDateAtMaxHour = (date: Date): Date => {
    let updatedDate = new Date(date)
    updatedDate.setHours(24, 59, 59, 59)
    return updatedDate
}

export const addBusinessDays = (date: Date, days: number): Date => {
    let resultDate = new Date(date)
    let daysToAdd = days
    while (daysToAdd > 0) {
        resultDate.setDate(resultDate.getDate() + 1)
        if (resultDate.getDay() !== 0 && resultDate.getDay() !== 6) {
            daysToAdd -= 1
        }
    }
    return resultDate
}

export const useInterval = (callback: () => void, delay: number) => {
    const savedCallback = useRef<() => void>()
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])
    useEffect(() => {
        const tick = () => {
            if (savedCallback.current) {
                savedCallback.current()
            }
        }
        if (delay !== null) {
            const id = setInterval(tick, delay)
            return () => {
                clearInterval(id)
            }
        }
    }, [callback, delay])
}

export function hasJsonStructure(str: unknown) {
    if (typeof str !== 'string') {
        return false
    }
    try {
        const result = JSON.parse(str)
        const type = Object.prototype.toString.call(result)
        return type === '[object Object]' || type === '[object Array]'
    } catch (err) {
        return false
    }
}

export const toNumberNull = (value: unknown): number | null => {
    if (typeof value === 'number') {
        return value
    }
    return Number.isNaN(Number(value)) ? null : Number(value)
}

export const toNumberZero = (value: unknown): number | null => {
    return toNumberNull(value) || 0
}

export const isNumber = (value: any): boolean => {
    if (typeof value === 'number') {
        return true
    }
    return !isNaN(value)
}

export const isNaNObject = (value: any): boolean => {
    // NaN is the only JavaScript object not equal to itself, so this is checking that value is not of NaN Type
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NaN
    return value !== value
}

// Converts a timezone time to UTC, then converted by Geneva to raw time without timezone
export const convertTime = (dateTime: Date | null | undefined | string) => {
    if (!dateTime) {
        return null
    }
    if (isNullOrUndeclared(dateTime)) {
        return null
    }
    if (typeof dateTime === 'string' || dateTime instanceof String) {
        const date = new Date(`01 01 1970 ${dateTime}`)
        return new Date(Date.UTC(1970, 1, 1, date.getHours(), date.getMinutes()))
    } else {
        return new Date(Date.UTC(1970, 1, 1, dateTime.getHours(), dateTime.getMinutes()))
    }
}

export const isObjectEmpty = (obj: any) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}

export const validatePhone = async (value: unknown) => {
    if (typeof value !== 'string') {
        return true
    }
    if (!isNullOrUndeclared(value)) {
        const {isValidPhoneNumber} = await import('libphonenumber-js')
        return isValidPhoneNumber(value, 'AU')
    }
    return false
}

export const contactMethodArrayRequiredCheck = (contact_methods: (string | null)[]) => {
    return !contact_methods.some((contact_method) => contact_method && contact_method.length > 0)
}

export function getUniqueValues(array: any[]) {
    return [...new Set(array)]
}

type EnumType = {[s: number]: string}

export const enumValues = (enumerable: EnumType): number[] => {
    return Object.keys(enumerable).flatMap((key) => {
        if (!isNaN(Number(key))) {
            return Number(key)
        } else {
            return []
        }
    })
}

export const accessByString = (obj: any, string: string) => {
    // Allows accessing nested properties of an object via a string. e.g. 'person.addresses[0].postcode'
    // Stolen from https://stackoverflow.com/a/6491621
    let s = string
    let o = obj
    s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    s = s.replace(/^\./, '') // strip a leading dot
    let attributes = s.split('.')
    for (const attribute of attributes) {
        if (!isNullOrUndeclared(o) && !isEmpty(o) && typeof o == 'object' && attribute in o) {
            o = o[attribute]
        } else {
            return
        }
    }
    return o
}

export const formatTimeStringForSelection = (date: string | Date | null) => {
    // 'eg. "09:00:00" to new Date(1970, 1, 1, 9, 0, 0)
    if (!date) {
        return null
    }
    if (date instanceof Date) {
        const start_time_string = date.toISOString()
        const start_time_string_without_tz = start_time_string.slice(0, start_time_string.length - 5)
        return new Date(start_time_string_without_tz)
    } else {
        return new Date('1970/01/01 ' + date)
    }
}

export const hexToRgbA = (hex: string, a: number = 1) => {
    // Applies transparency to hex colour by converting to rgba with given alpha value
    let c: any
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ', ' + String(a) + ')'
    }
    throw new Error(`Invalid hex: '${hex}'`)
}

export const sharesCommonElement = (array1: any[], array2: any[]) => {
    for (const element of array1) {
        if (array2.includes(element)) {
            return true
        }
    }
    return false
}

export interface FinancialYearOption {
    label: string
    value: string
    start_date: Date
    end_date: Date
}

export const financialYearsList = () => {
    const startDate = new Date(2021, 6, 5)
    const currentDate = new Date()
    const years = currentDate.getFullYear() - startDate.getFullYear()
    let financialYearList: FinancialYearOption[] = []
    for (let i = 0; i < years + 1; i++) {
        financialYearList.push({
            label: `FY ${21 + i}/${21 + i + 1}`,
            start_date: firstDayOfFinancialYearForDate(addYears(new Date(startDate), i)),
            end_date: lastDayOfFinancialYearForDate(addYears(new Date(startDate), i)),
            value: formatDate(addYears(new Date(startDate), i)),
        })
    }
    return financialYearList
}

// Used to start a financial year selector in the correct calendar year
export const initialYearIndex = new Date().getMonth() < 6 ? 2 : 1

export const firstDayOfWeekForDate = (date: Date) => {
    const d = new Date(date)
    const day = d.getDay()
    const diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
    return new Date(d.setDate(diff))
}

export const lastDayOfWeekForDate = (date: Date) => {
    return addDays(firstDayOfWeekForDate(date), 6)
}

const dateRangeOverlaps = (a_start: Date, a_end: Date, b_start: Date, b_end: Date) => {
    if (a_start <= b_start && b_start <= a_end) return true // b starts in a
    if (a_start <= b_end && b_end <= a_end) return true // b ends in a
    if (b_start <= a_start && a_end <= b_end) return true // a in b
    return false
}

export const daysBetweenDates = (a: Date, b: Date) => {
    const MS_PER_DAY = 1000 * 60 * 60 * 24
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
    return Math.floor((utc2 - utc1) / MS_PER_DAY)
}

export const minutesBetweenDates = (a: Date, b: Date) => {
    return Math.abs(a.getTime() - b.getTime()) / 60000
}

export interface DateRangeInterface {
    from: Date
    to: Date
}

export const multipleDateRangeOverlaps = (dateRanges: DateRangeInterface[]) => {
    let i = 0,
        j = 0
    let dateIntervals = dateRanges.filter((entry) => entry.from != null && entry.to != null)

    if (dateIntervals != null && dateIntervals.length > 1)
        for (i = 0; i < dateIntervals.length - 1; i += 1) {
            for (j = i + 1; j < dateIntervals.length; j += 1) {
                if (
                    dateRangeOverlaps(
                        dateIntervals[i].from,
                        dateIntervals[i].to,
                        dateIntervals[j].from,
                        dateIntervals[j].to,
                    )
                )
                    return true
            }
        }
    return false
}

export const getModeModifiedColor = (color: string, mode: 'light' | 'dark', coefficient: number = 0.9) => {
    if (mode === 'dark') {
        return darken(color, coefficient)
    } else {
        return lighten(color, coefficient)
    }
}

export const percentageDifference = (a: number, b: number) => {
    if (a === b) {
        return 0
    }
    if (a === 0) {
        return 1
    }
    return (b - a) / a
}

export interface DictionaryInterface<T> {
    [Key: string | number]: T
}

export const sortObjectsByProperty = (array: any[], property: string, direction: 'asc' | 'desc' = 'asc') => {
    return array.sort((a, b) => {
        if (a[property] < b[property]) {
            return direction === 'asc' ? -1 : 1
        }
        if (a[property] > b[property]) {
            return direction === 'asc' ? 1 : -1
        }
        return 0
    })
}

export const objectToUrlParams = (obj: DictionaryInterface<any>) => {
    return new URLSearchParams(
        Object.entries(obj)
            .filter((pair) => {
                return !isNullOrUndeclared(pair[1])
            })
            .map((pairs) => {
                return [pairs[0], String(pairs[1])]
            }),
    )
}

export const getUrlWithParamsString = (url: string, params: DictionaryInterface<any>) => {
    if (isNullOrUndeclared(params) || isEmpty(params)) {
        return url
    }
    const urlParamsString = objectToUrlParams(params).toString()
    if (urlParamsString) {
        return url + '?' + urlParamsString
    }
    return url
}

export const objectMap = (obj: any, fn: (value: any, key: string, index: number) => any) =>
    Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))

export const getChangedValues = (oldObj: any, newObj: any, primaryKeys = ['id']) => {
    return Object.entries(newObj).reduce((acc: any, [key, value]) => {
        if (oldObj[key] !== value || primaryKeys.includes(key)) {
            acc[key] = value
        }
        return acc
    }, {})
}

export const getBaseUrl = (url: string) => {
    return url.split('?')[0]
}

export const handleOpenLinkInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) {
        newWindow.opener = null
    }
}

export const devThemeOverrideSettings = (settings: SettingsInterface) => {
    let devTheme: string
    let overrideSettings = {...settings}
    if (['DARK', 'NATURE', 'SUNSET', 'AUNTY_GRACE_DARK', 'DEV_DARK'].includes(overrideSettings.theme)) {
        // Dark Themes
        devTheme = 'DEV_DARK'
    } else {
        devTheme = 'DEV_LIGHT'
    }
    overrideSettings = {...overrideSettings, theme: devTheme}
    return overrideSettings
}

// Data must be a base64 string returned from backend
export const handleBase64PDFDownload = (data: string, fileName: string) => {
    const downloadLink = document.createElement('a')
    downloadLink.href = `data:application/pdf;base64,${data}`
    downloadLink.download = fileName
    downloadLink.click()
}

export function convertPDFToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = function (event) {
            const target = event.target as FileReader
            const base64String = target.result as string
            resolve(base64String)
        }

        reader.onerror = function (error) {
            reject(error)
        }

        reader.readAsDataURL(file)
    })
}

export type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U
}

export function bytesToSize(bytes: number, decimals = 2): string {
    if (bytes === 0) {
        return '0 Bytes'
    }

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const sumAttribute = (array: any[], attribute: string) => {
    return array.reduce((sum, item) => sum + item[attribute], 0)
}

export function scrambleText(text: string | null) {
    if (!text) {
        return null
    }
    const chars = text.split('')
    for (let b = chars.length - 1; 0 < b; b--) {
        let c = Math.floor(Math.random() * (b + 1))
        let d = chars[b]
        chars[b] = chars[c]
        chars[c] = d
    }
    return chars.join('')
}
