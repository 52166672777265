import {FC, ReactNode, createContext, useEffect, useState} from 'react'
import {ConstantsInterface, getConstants} from '@/lib/api/geneva/lookups'

interface ConstantsProviderProps {
    children: ReactNode
}

interface ConstantsState {
    constants: ConstantsInterface | null
}

const initialState: ConstantsState = {
    constants: null,
}

const ConstantsContext = createContext<ConstantsState>(initialState)

export const ConstantsProvider: FC<ConstantsProviderProps> = ({children}) => {
    const [constants, setConstants] = useState<ConstantsInterface | null>(null)

    useEffect(() => {
        const setConstantsData = async () => {
            try {
                const {data} = await getConstants()
                return data
            } catch (err: any) {
                console.error('ConstantsProvider', err)
                throw err
            }
        }
        setConstantsData().then((data) => setConstants(data))
    }, [])

    return (
        <ConstantsContext.Provider
            value={{
                constants,
            }}
        >
            {children}
        </ConstantsContext.Provider>
    )
}

export default ConstantsContext
