import {FC, ReactNode} from 'react'
import useIsBusy from '@/hooks/useIsBusy'
import dynamic from 'next/dynamic'

const Backdrop = dynamic(() => import('@mui/material/Backdrop'), {ssr: false})
const CircularProgress = dynamic(() => import('@mui/material/CircularProgress'), {ssr: false})

interface IsBusyBackdropInterface {
    children?: ReactNode
}

const IsBusyBackdrop: FC<IsBusyBackdropInterface> = ({children}) => {
    const {isBusy} = useIsBusy()

    return (
        <>
            {isBusy && (
                <Backdrop sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 101})} open={isBusy}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            {children}
        </>
    )
}
export default IsBusyBackdrop
